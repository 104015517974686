body {
  margin: 0;
  background-color: #f5f3ed;
  /* font-family: 'PlusJakartaSans'; */
  font-family: 'Plus Jakarta Sans', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
@font-face {
  /* font-family: 'PlusJakartaSans'; */
  font-family: 'Roboto', sans-serif;
  src: url('./assets/fonts/PlusJakartaSans-Light.ttf');
}

.react-responsive-modal-modal {
  max-width: 100% !important;
  margin: 0rem !important;
  border-radius: 0.5rem;
}

.customModalShare {
  height: 16rem;
  max-height: 16rem;
}

.customRestaurantModal{
  height: 36rem;
  max-height: 36rem;
  width: 38rem;
  max-width: 38rem;
  background-color: #f5f3ed !important;
}

.customRestModal {
  height: 36rem;
  max-height: 36rem;
  width: 38rem;
  max-width: 38rem;
  background-color: #f5f3ed !important;
}

video::-webkit-media-controls-fullscreen-play-button {
  display: initial !important;
}

@media only screen and (min-width: 0px) and (max-width: 555px) {
  .commentModal, .customRestaurantModal {
    max-width: 90% !important;
  }
  .reportModal {
    width: 22rem !important;
  }
  .mediaHeight {
    height: auto !important;
  }

/* video::-webkit-media-controls {
  display: none;
}

video::-webkit-media-controls {
  display: none;
} */


}


@media only screen and (min-width: 0px) and (max-width: 382px) {
  .customRestaurantModal {
    width: 22rem !important;
  }
  .mediaHeight {
    height: auto !important;
  }
}

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}

@media only screen and (min-width: 556px) and (max-width: 767px) {
  .react-responsive-modal-modal {
    width: 500px;
  }
}
@media only screen and (min-width: 680px) and (max-width: 555px) {
  .react-responsive-modal-modal {
    width: 400px;
  }
}
@media only screen and (min-width: 383px) and (max-width: 439px) {
  .react-responsive-modal-modal {
    width: 350px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 382px) {
  .react-responsive-modal-modal {
    width: 300px;
  }
}

.slide.slide-current.slide-visible {
  display: flex;
  justify-content: center;
  /* background: white; */
}

.react-responsive-modal-closeButton {
  display: none !important;
}
.paging-item.active > button {
  fill: #e1684a !important;
}
.slider-control-bottomcenter {
  position: relative;
  bottom: -2.4rem;
}
@media only screen and (max-width: 992px) {
  .slider-control-bottomcenter {
    /* position: relative;
		bottom: 0rem; */
  }
}

.slider-container {
  /* max-height: 24rem !important; */
  margin-bottom: 2.5rem;;
}
.slider-frame {
  /* height: max-content !important; */
}
.css-1qk1t6n {
  /* max-height: auto !important; */
  max-height: 24rem !important;
}
.css-ldi0dn {
  display: none !important;
}
.css-djit9u {
  /* max-height: 100% !important; */
  max-height: 21rem !important;
}
.css-djit9u:hover {
  /* max-height: auto !important; */
  max-height: 22rem !important;
}
.css-djit9u:focus-visible {
  /* max-height: auto !important; */
  max-height: 23rem !important;
}
.css-djit9u,
.css-t2klrc,
.css-ldi0dn,
.css-19gp5mi {
  width: auto !important;
  /* max-height: auto !important; */
  max-height: 24rem !important;
}

/* switch button style */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 3px;
  bottom: -4px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #438d95;
}

input:focus + .slider {
  box-shadow: 0 0 1px #438d95;
}

input:checked + .slider:before {
  background-color: #ccc;
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
input + .slider:before {
  left: 0;
  background-color: #438d95;
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*switch button style*/
/* video player controls */
.css-18wbp41 {
  display: none;
}

video::-webkit-media-controls-fullscreen-button {
  background-color: none;
  color: none !important;
}
video::-webkit-media-controls-fullscreen-button:hover {
  box-shadow: none !important;
}
video::-webkit-media-controls-play-button {
  display: none !important;
}
video::-webkit-media-controls-timeline {
  display: none !important;
}
video::-webkit-media-controls-current-time-display {
  display: none !important;
}
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}
video::-webkit-media-controls-mute-button {
  display: none !important;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}
video::-webkit-media-controls-volume-slider {
  display: none !important;
}

video::-moz-list-bullet {
  display: none !important;
}
video::-ms-backdrop {
  display: none !important;
}
video::-ms-tooltip {
  display: none !important;
}

video::-moz-selection {
  display: none !important;
}
.nsm7Bb-HzV7m-LgbsSe{
  box-shadow: none !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 0.8rem !important;
  border: 0.063rem solid lightgray !important;
  max-width: 100% !important;
  width: 100% !important;
  background-color: white;
  padding: 0.5rem 0rem 0.5rem 0rem !important;
  height: 47px !important;
  border-radius: 0  !important;
  color: rgba(0,0,0,0.54) !important;
}
.nsm7Bb-HzV7m-LgbsSe:hover{
  background: white !important;
}

.gmnoprint img {
	border-radius: 50% !important;
}

.rounded-marker {
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  overflow: hidden !important;
}

.rounded-marker img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.divWrapper{
  position: relative;
  top: 4.5rem;
}

.confirmationModel {
  height: 10rem;
  max-height: 10rem;
}

#container{
  padding: 0 !important;
  width: 100% !important;
}
.oauth-container {
  position: relative;
  width: 100% !important;
}

